import React from 'react';
import { connect } from 'react-redux';
import {
    getFormattedDate,
    isForcefulRedirect,
    IssueDetails,
} from '../../../utils/utils';
import { FLAGGED_SINCE_DATE_FORMAT } from '../../../constants/CommonConstants';
import { CreativeReportTabs } from './CreativeReportTabs';
import ExpandableList from '../ExpandableList';
import { NotAvailable } from '../CommonComponents';
import CopyText from '../CopyText';
import LinkCopy from '../LinkCopy';
import TooltipComponent from '../../shared/TooltipComponent';
import { getFieldWrapper } from '../../shared/ReportModals/helpers';
import { scanLogReportByTi } from '../../../actions/AlertLogReportActions';
import ReportModalWrapper from '../../shared/ReportModals/ReportModalWrapper';
import { ConditionalWrapper } from '../../../utils/utils';
import { createListOfSensitiveImagery } from '../helpers';

class ScanLogSSPCreativeReport extends React.Component {
    componentDidMount() {
        if (!this.props.isModal) {
            if (this.props.match.params.ti) {
                // extract token out of the query string for the request
                const { search } = this.props.location;
                const token = new URLSearchParams(search).get('token');

                this.props.loadScanLogReportByTi(
                    this.props.match.params.ti,
                    token
                );
            }
        }
    }

    render() {
        const {
            category,
            isQuality,
            isSecurity,
            type,
            creative_id,
            status,
            dsp,
            dimensions,
            seat,
            ad_type,
            confiant_identified,
            other_issues,
            threat_info,
            landing_page,
            screenshot,
            adomains,
            networkLoad,
            resources,
            har,
            ad_instance,
            brands,
            categories,
            issue_detail,
            ssl_error,
            links,
            textOcr,
            audioTranscription,
            isModal,
            show,
            onHide,
            shareableReportLink,
            sensitiveImagery,
            accountType,
            sentiment,
        } = this.props;

        const otherIssues =
            other_issues && other_issues.length
                ? other_issues.map(i => <li key={Math.random()}>{i}</li>)
                : null;

        const isForceful = isForcefulRedirect(type, other_issues);

        let title = IssueDetails(category, type, false, true);

        let categoriesData = (categories && categories.length && (
            <ExpandableList
                key={`categories-${Math.random()}`}
                data={categories}
            />
        )) || <NotAvailable />;

        let brandsData = (brands && brands.length && (
            <ExpandableList key={`brands-${Math.random()}`} data={brands} />
        )) || <NotAvailable />;

        let sensitiveImageryData =
            sensitiveImagery && Array.isArray(sensitiveImagery) ? (
                sensitiveImagery.length ? (
                    createListOfSensitiveImagery(sensitiveImagery)
                ) : (
                    <NotAvailable text="None detected" />
                )
            ) : null;

        return (
            !this.props.isApiCallInProgress && (
                <ConditionalWrapper
                    condition={isModal}
                    wrapper={children => (
                        <ReportModalWrapper
                            show={show}
                            hide={onHide}
                            title={'Scan Report'}
                        >
                            {children}
                        </ReportModalWrapper>
                    )}
                >
                    <div
                        id={!isModal ? `creative-report-shared` : ''}
                        className="row"
                    >
                        <div className="col-5 col-md-3">
                            {!isModal ? (
                                <img
                                    id="scan-report-logo"
                                    className="img-fluid"
                                    src={'/static/images/scan_report.png'}
                                    alt="Scan Report"
                                />
                            ) : (
                                <></>
                            )}
                            {title}
                            <div className="leftpane-subtitle">
                                {getFieldWrapper(
                                    'creative_id',
                                    creative_id ? (
                                        <CopyText
                                            id={`creative_id-${creative_id}`}
                                            className="d-flex align-items-center justify-content-end"
                                            text={creative_id}
                                        />
                                    ) : null,
                                    true
                                )}
                            </div>
                            <div
                                className={`leftpane-subtitle ${isQuality || isSecurity
                                        ? 'security-quality'
                                        : ''
                                    }`}
                            >
                                {getFieldWrapper('status', status, true)}

                                {(issue_detail &&
                                    getFieldWrapper(
                                        'issue_detail',
                                        <TooltipComponent
                                            copyAct={true}
                                            placement="right"
                                            id={`issue_detail-${issue_detail}`}
                                        >
                                            {issue_detail}
                                        </TooltipComponent>,
                                        true
                                    )) ||
                                    null}

                                {(dimensions &&
                                    getFieldWrapper(
                                        'dimensions',
                                        dimensions,
                                        true
                                    )) ||
                                    null}

                                {(ad_type &&
                                    getFieldWrapper(
                                        'ad_type',
                                        ad_type,
                                        true
                                    )) ||
                                    null}

                                {(dsp &&
                                    getFieldWrapper(
                                        accountType === 'dsp' ? 'seat' : 'dsp',
                                        dsp,
                                        true
                                    )) ||
                                    null}

                                {(accountType !== 'dsp' &&
                                    seat &&
                                    getFieldWrapper('seat', seat, true)) ||
                                    null}

                                {(typeof ssl_error === 'boolean' &&
                                    getFieldWrapper(
                                        'ssl_error',
                                        ssl_error.toString().toUpperCase(),
                                        true
                                    )) ||
                                    null}
                            </div>
                            <div className="leftpane-subtitle">
                                {getFieldWrapper('brand', brandsData, true)}
                                {getFieldWrapper(
                                    'category',
                                    categoriesData,
                                    true
                                )}
                                {sensitiveImageryData &&
                                    getFieldWrapper(
                                        'sensitiveImagery',
                                        sensitiveImageryData,
                                        true
                                    )}
                            </div>
                            <div
                                className={
                                    !otherIssues && !sentiment
                                        ? 'leftpane-subtitle-no-border'
                                        : 'leftpane-subtitle'
                                }
                            >
                                {confiant_identified &&
                                    getFieldWrapper(
                                        'confiant_identified',
                                        confiant_identified
                                            ? getFormattedDate(
                                                confiant_identified,
                                                'MMM D, YYYY',
                                                FLAGGED_SINCE_DATE_FORMAT
                                            )
                                            : null,
                                        true
                                    )}
                                {ad_instance &&
                                    getFieldWrapper(
                                        'ad_instance',
                                        ad_instance ? (
                                            <CopyText
                                                id={`ad_instance_id-${ad_instance}`}
                                                className="d-flex align-items-center justify-content-end"
                                                text={ad_instance}
                                            />
                                        ) : null,
                                        true
                                    )}
                            </div>
                            {sentiment && (
                                <div
                                    className={
                                        !otherIssues
                                            ? 'leftpane-subtitle-no-border'
                                            : 'leftpane-subtitle'
                                    }
                                >
                                    {sentiment &&
                                        sentiment.sentiment_score &&
                                        getFieldWrapper(
                                            'sentiment_score',
                                            sentiment.sentiment_score,
                                            true
                                        )}
                                    {sentiment &&
                                        sentiment.language &&
                                        getFieldWrapper(
                                            'sentiment_language',
                                            sentiment.language,
                                            true
                                        )}
                                </div>
                            )}
                            {(otherIssues &&
                                getFieldWrapper(
                                    'other_issues',
                                    <ul className="leftpane-list">
                                        {otherIssues}
                                    </ul>,
                                    true
                                )) ||
                                null}
                            {this.props.isModal && shareableReportLink && (
                                <LinkCopy
                                    type="scan_report"
                                    report_url={shareableReportLink}
                                />
                            )}
                        </div>

                        <CreativeReportTabs
                            type={
                                isQuality
                                    ? 'quality'
                                    : isSecurity
                                        ? 'security'
                                        : 'noType'
                            }
                            threatInfo={threat_info}
                            screenshot={screenshot}
                            landing_page={landing_page}
                            adomains={adomains}
                            networkLoad={networkLoad}
                            resources={resources}
                            har={har}
                            ad_instance={ad_instance}
                            links={links}
                            textOcr={textOcr}
                            audioTranscription={audioTranscription}
                            issue_type={type}
                            issue_detail={issue_detail}
                            isForceful={isForceful}
                        />
                    </div>
                </ConditionalWrapper>
            )
        );
    }
}

const mapStateToProps = state => {
    return {
        category: state.alertLogReport.issue_category,
        isQuality: state.alertLogReport.issue_category === 'quality',
        isSecurity: state.alertLogReport.issue_category === 'security',
        type: state.alertLogReport.issue_type,
        creative_id: state.alertLogReport.creative_id,
        status: state.alertLogReport.status,
        dimensions: state.alertLogReport.dimensions,
        ad_type: state.alertLogReport.ad_type,
        dsp: state.alertLogReport.dsp,
        seat: state.alertLogReport.seat,
        confiant_identified: state.alertLogReport.date_identified,
        other_issues: state.alertLogReport.other_issues,
        screenshot: state.alertLogReport.screenshot,
        landing_page: state.alertLogReport.landing_page,
        threat_info: state.alertLogReport.threat_info,
        adomains: state.alertLogReport.adomains,
        networkLoad: state.alertLogReport.networkLoad,
        resources: state.alertLogReport.resources,
        har: state.alertLogReport.har,
        ad_instance: state.alertLogReport.ad_instance,
        brands: state.alertLogReport.brands,
        categories: state.alertLogReport.categories,
        issue_detail: state.alertLogReport.issue_detail,
        ssl_error: state.alertLogReport.ssl_error,
        links: state.alertLogReport.links,
        textOcr: state.alertLogReport.textOcr,
        audioTranscription: state.alertLogReport.audioTranscription,
        token: state.alertLogReport.token,
        shareableReportLink: state.alertLogReport.shareable_report_link,
        sensitiveImagery: state.alertLogReport.sensitiveImagery,
        isApiCallInProgress: state.apiCallStatus.isApiCallInProgress,
        accountType: state.settings ? state.settings.settings.account_type : '',
        sentiment: state.alertLogReport.sentiment,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadScanLogReportByTi: (ti, token) =>
            dispatch(scanLogReportByTi(ti, token)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScanLogSSPCreativeReport);
