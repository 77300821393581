import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TooltipComponent from '../shared/TooltipComponent';

class LinkCopy extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            copied: false,
        };

        this.onCopy = this.onCopy.bind(this);
    }

    onCopy() {
        this.setState({ copied: true });
        if (this._isMounted) {
            setTimeout(() => this.setState({ copied: false }), 3000);
        }
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {
            landing_page,
            adomain,
            type,
            threat_url,
            report_url,
            parentComp,
        } = this.props;

        if (type === 'landing') {
            return (
                <React.Fragment>
                    <div
                        className={
                            parentComp === 'tab'
                                ? 'tab-landing pr-2'
                                : 'notab-landing'
                        }
                    >
                        Landing Page URL
                    </div>
                    {landing_page && landing_page.url ? (
                        <div className={`text-url ${parentComp}`}>
                            <span className={`truncate-text ${parentComp}`}>
                                {landing_page.url}
                            </span>
                            <CopyToClipboard
                                onCopy={this.onCopy}
                                text={landing_page.url}
                            >
                                <i className="far fa-copy fa-lg"></i>
                            </CopyToClipboard>
                        </div>
                    ) : (
                        <div className="no-data">Not enough data</div>
                    )}{' '}
                    <section className={`copied-text ${parentComp}`}>
                        {this.state.copied ? (
                            <span className="copied-text">Copied</span>
                        ) : null}
                    </section>
                </React.Fragment>
            );
        }

        if (type === 'adomain') {
            return (
                <React.Fragment>
                    {adomain && adomain !== '' ? (
                        <div className="text-url">
                            <span
                                className={`truncate-text`}
                                style={{ textAlign: 'justify' }}
                            >
                                <TooltipComponent id={`adomain-${adomain}`}>
                                    {adomain}
                                </TooltipComponent>
                            </span>
                            <CopyToClipboard
                                onCopy={this.onCopy}
                                text={adomain}
                            >
                                <i className="far fa-copy fa-lg"></i>
                            </CopyToClipboard>
                        </div>
                    ) : (
                        <div className="no-data">Not enough data</div>
                    )}{' '}
                    <section className="copied-text">
                        {this.state.copied ? (
                            <span className="copied-text">Copied</span>
                        ) : null}
                    </section>
                </React.Fragment>
            );
        }

        if (type === 'threat_url') {
            return (
                <React.Fragment>
                    {threat_url && threat_url !== '' ? (
                        <div className="text-url">
                            <span className="truncate-text">{threat_url}</span>
                            <CopyToClipboard
                                onCopy={this.onCopy}
                                text={threat_url}
                            >
                                <i className="far fa-copy fa-lg"></i>
                            </CopyToClipboard>
                        </div>
                    ) : (
                        <div className="no-data">Not enough data</div>
                    )}{' '}
                    <section className="copied-text">
                        {this.state.copied ? (
                            <span className="copied-text">Copied</span>
                        ) : null}
                    </section>
                </React.Fragment>
            );
        }

        if (type === 'scan_report') {
            return (
                <React.Fragment>
                    <div className="scan-report-share mt-3">
                        <CopyToClipboard onCopy={this.onCopy} text={report_url}>
                            <button
                                type="button"
                                className="btn btn-primary width-175"
                            >
                                SHARE REPORT
                                <i className="far fa-copy fa-lg"></i>
                            </button>
                        </CopyToClipboard>
                    </div>
                    <section className="copied-text-report">
                        {this.state.copied ? (
                            <span className="copied-text-report">
                                Link Copied
                            </span>
                        ) : null}
                    </section>
                </React.Fragment>
            );
        }
    }
}

export default LinkCopy;
